import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const EscalaByMonth = loadable(() => import('@screens/escala/EscalaByMonth'));

function Index(props) {
  return (
    <Layout {...props}>
      <EscalaByMonth {...props} />
    </Layout>
  );
}

export default Index;
